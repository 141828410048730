.header {
    position: relative;
    background-color: $color-white;
    z-index: 5;
    transition: .35s ease-in-out;

    &__container {
        display: flex;
        align-items: center;
        height: 140px;
        transition: .35s ease-in-out;
    }

    &__bottom {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 2;

    &__title {
        margin: 0;
        font-size: 0;
        line-height: 0;
    }

    svg {
        width: 355px;
        height: 50px;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 15px;
    z-index: 2;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        background-color: $color-bg--transparent;
        border: 0;
        cursor: pointer;

        svg {
            fill: $color-text;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            svg {
                fill: $color-fifth;
            }
        }
    
    }

}

.header--fixed {
    position: fixed;
    width: 100%;
    box-shadow: 0px 1px 20px rgba(8, 18, 33, 0.08);

    .header {
        &__container {
            height: 90px;
        }
    }

    .tools {
        column-gap: 10px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__container {
            height: 120px;
        }
    }

    .identity {
        svg {
            width: 250px;
            height: 35px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .identity {
        svg {
            height: 50px;
            width: 355px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        &__container {
            height: 140px;
            flex-direction: column-reverse;
        }

        &__bottom {
            height: 40px;
            position: relative;

            &:before {
                content: '';
                top: 0;
                height: 100%;
                width: 100vw;
                background-color: $color-main;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }
        }
    }

    .identity {
        position: relative;
        height: 100px;

        svg {
            width: 285px;
            height: 40px;
        }
    }

    .tools {
        height: 40px;

        &__item {
            &:hover, &:focus-within {
                background-color: $color-bg--transparent;
            }
            
            svg {
                fill: $color-white;
            }
        }
    }
}
