.home {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    &__title {
        font-size: toRem(55);
        line-height: toRem(60);
        text-transform: uppercase;
        margin: 0 0 5px;

        &:before {
            display: none;
        }
    }

    &__subtitle {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-family: $font-family--heading;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-size: $font-size--5;
        line-height: toRem(20);
    }

    .button {
        margin: 20px 0 0;
        padding: 10px 25px;
    }

    &__nav {
        width: 70px;
        height: 70px;
        display: flex;
        flex-direction: column;
        background-color:$color-white;
        position: absolute;
        align-items: center;
        z-index: 2;
    }

    .swiper-button {
        padding: 0;
        border: none;
        margin: 0;
        z-index: 1;
        position: relative;
        top: auto;
        height: 30px;
        width: 30px;
        flex: 1 1 auto;

        svg {
            position: relative;
            width: 30px;
            height: 30px;
            fill: $color-main;
            flex: none;
        }


        &:after {
            display: none;
        }

        &-next {
            right: auto;
        }

        &-prev {
            left: auto;
        }

    }

    //======================================================================================================
    // SlideShow - A la une
    //======================================================================================================
    .alu {
        position: relative;

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--nav {
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none
            }
        }

        .slideshow {
            @include size(100%, 550px);

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            &__content {
                @include flex($direction: column);
                box-sizing: border-box;
                @include absolute($left: 155px, $bottom: -1px);
                @include size(435px, auto);
                padding: 30px 30px 0;
                background: $color-light;
                color: $color-main;
            }

            &__title {
                margin: 0;
                font-family: $font-family--heading;
                font-size: toRem(30);
                line-height: toRem(35);
                font-weight: $font-weight-extrabold;
            }

            &__description {
                font-size: $font-size--5;
                margin-top: 10px;
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Navigation (arrow)
            &__nav {
                @include absolute($bottom: 0, $left: 20px);
                pointer-events: visible;
                background-color: $color-light;
            }
        
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access-demarches {
        background-color: $color-light;

        > .container {
            display: flex;
        }
    }

    .access {
        padding: 80px 0;
        width: 670px;
        flex: none;

        &__list {
            display: flex;
            flex-wrap: wrap;
            column-gap: 80px;
            row-gap: 40px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            width: 120px;

            &:hover, &:focus-within {
                .access {
                    &__svg-container {
                        svg {
                            path {
                                fill: $color-second;
                            }
                        }
                    }

                    &__label {
                        &__strong {
                            color: $color-second;
                        }
                    }
                }
            }
        }

        &__svg-container {
            svg {
                path {
                    transition: $duration;
                }
            }
        }

        &__label {
            text-transform: uppercase;
            font-weight: $font-weight-light;
            color: $color-main;
            line-height: toRem(20);

            @include font-size(18);

            &__strong {
                font-weight: $font-weight-extrabold;
                text-transform: none;
                display: block;
                transition: $duration;
            }
        }
    }

    //======================================================================================================
    // Démarches
    //======================================================================================================
    .demarches {
        position: relative;
        padding: 80px 0 80px 80px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1000px;
            height: 100%;
            background-color: $color-second;
        }

        &__title {
            position: relative;
            z-index: 1;
            margin: 0 0 20px;
            font-family: $font-family--heading;
            color: $color-white;
            font-weight: $font-weight-extrabold;
            line-height: toRem(30);

            @include font-size(25);
        }

        &__list {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            position: relative;
            z-index: 1;
        }

        &__text {
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-family: $font-family--heading;
            font-weight: $font-weight-light;
            color: $color-white;
            line-height: toRem(20);

            @include font-size(20);

            &:before {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_plus.svg) no-repeat;
                transform: rotate(0);
                margin-right: 0;
                flex: none;
                transition: $duration;
            }

            &:hover, &:focus-within {
                &:before {
                    transform: rotate(90deg);
                    margin-right: 10px;
                }
            }
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        padding-top: 100px;

        &__subtitle {
            &:before {
                content: '';
                width: 19px;
                height: 20px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_titre_actus.svg) no-repeat;
                background-size: contain;
            }
        }

        &__list {
            padding-left: 70px;
            width: 100vw;
            position: relative;
        }

        &__nav {
            bottom: 0;
            left: 70px;
        }

        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            width: 535px;
            height: 340px;
            margin-right: 30px;
        }

        &__item {
            position: relative;
            display: flex;
            height: 100%;

            &:hover, &:focus-within {
                .posts {
                    &__item {
                        &__container-img {            
                            img {
                                transform: scale(1.1);
                            }
                        }

                        &__content {
                            &:before {
                                width: 10px;
                            }
                        }
                    }
                }
            }

            &__container-img {
                width: 100%;
                height: 100%;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat center $color-light;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: .35s;
                }
            }

            &__content {
                padding: 20px 30px 0;
                background-color: $color-white;
                position: absolute;
                bottom: 0;
                right: 35px;
                width: 380px;
                height: 110px;
                box-sizing: border-box;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 100%;
                    background-color: $color-fifth;
                    transition: $duration;
                }
            }

            &__title {
                @extend h3;
                margin: 5px 0 0;

                &:before {
                    display: none;
                }
            }

            &__taxo {
                font-family: $font-family--heading;
                line-height: toRem(25);
                font-weight: $font-weight-light;
                text-transform: uppercase;
            }
        }
    }

    //======================================================================================================
    // Evènements
    //======================================================================================================
    .events {
        padding-top: 120px;

        &__subtitle {
            &:before {
                content: '';
                width: 26px;
                height: 20px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_titre_agenda.svg) no-repeat;
                background-size: contain;
            }
        }

        &__list {
            padding-left: 70px;
            position: relative;
        }

        &__nav {
            top: 180px;
            left: 70px;
        }

        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            width: 345px;
            margin-right: 32.5px;
        }

        &__item {
            &:hover, &:focus-within {
                .events {
                    &__item {
                        &__container-img {
                            img {
                                transform: scale(1.1);
                            }
                        }

                        &__date {
                            &:before {
                                width: 10px;
                            }
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                width: 100%;
                height: 250px;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat center $color-light;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: .35s;
                }
            }

            &__date {
                position: absolute;
                bottom: 0;
                right: 30px;
                height: 45px;
                background-color: $color-white;
                color: $color-text;
                font-family: $font-family--heading;
                font-weight: $font-weight-light;
                text-transform: uppercase;
                font-size: toRem(20);
                line-height: toRem(25);
                display: flex;
                align-items: flex-end;
                padding: 0 30px;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 100%;
                    background-color: $color-second;
                    transition: $duration;
                }
            }

            &__title {
                @extend h3;
                margin: 20px 0 0;

                &:before {
                    display: none;
                }
            }
        }
    }

    //======================================================================================================
    // Social wall - Réseaux
    //======================================================================================================
    .sw {
        padding-top: 120px;
        position: relative;
        z-index: 0;

        &__subtitle {
            &:before {
                content: '';
                width: 20px;
                height: 20px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_titre_reseaux.svg) no-repeat;
                background-size: contain;
            }
        }

        &__buttons {
            display: flex;
            column-gap: 20px;
        }

        &__btn {
            column-gap: 10px;
            padding: 5px 25px;

            svg {
                width: 30px;
                height: 30px;
            }

            &--fb {
                &:after {
                    border-color: $color-fourth;
                }
            }
        }

        &__container {
            padding-left: 60px;
        }

        .ff-stream {
            background: none!important;
            min-height: 0!important;
            padding: 0!important;
            margin: 0 -30px !important;
        }

        .ff-item {
            height: 240px!important;

            &:hover {
                .ff-content {
                    opacity: 1;
                }
            }
        }

        .ff-item .ff-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 30px 30px 50px;
            z-index: 2;
            opacity: 0;
            height: 240px!important;
            color: $color-white!important;
            transition: all .25s ease-in-out;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background:linear-gradient(0deg, rgba(8, 18, 33, 0.7), rgba(8, 18, 33, 0.7));
                z-index: -1;
                opacity: 1;
                transition: all .25s ease-in-out;
            }
        }

        .ff-item-cont {
            height: 240px!important;
        }

        .ff-item .ff-item-bar {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            border-top: 0!important;
            background-color: $color-white;
            margin: 0 30px;
            height: 45px!important;
            padding: 0 15px 0!important;
            display: flex;
            align-items: flex-end;
            transition: all .25s ease-in-out;

            a {
                color: $color-main!important;

                &:hover {
                    i:after {
                        display: none!important;
                    }
                }
            }
        }

        .ff-item .ff-img-holder{
            height: 240px!important;
            z-index: 0;
        }

        .ff-stream-wrapper {
            padding: 0!important;
            margin: 0!important;
        }
        
        .ff-loadmore-wrapper, 
        .ff-item-meta,
        .picture-item__inner:after{
            display: none!important;
        }

        .ff-content a,
        .ff-item a {
            color: $color-white;
        }

        .picture-item__inner {
            box-shadow: none!important;
        }

        .ff-share-wrapper {
            top: 0;
            flex: 1;
        }
    }

    //======================================================================================================
    // Publications
    //======================================================================================================
    .publications {
        padding-top: 120px;

        &__subtitle {
            &:before {
                content: '';
                width: 14px;
                height: 25px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_titre_publications.svg) no-repeat;
                background-size: contain;
            }
        }

        &__container {
            padding-left: 60px;
            display: flex;
            justify-content: space-between;
        }

        &__admin {
            display: flex;
            align-items: center;
            column-gap: 30px;

            &__image {
                width: 270px;
                height: 375px;
                flex: none;
    
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &__category {
                font-family: $font-family--heading;
                line-height: toRem(25);
                text-transform: uppercase;
                margin: 0 0 20px;
            }

            &__title {
                line-height: toRem(30);
                margin: 0 0 20px;

                &:before {
                    display: none;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                width: 215px;
            }

            &__date {
                display: block;
                font-size: $font-size--text-small;
                margin: 0 0 45px;
            }

            &__buttons {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .link-document {
                    margin: 0;
                    width: 30px;
                    height: 30px;

                    &:hover, &:focus {
                        svg {
                            fill: $color-third;
                        }
                    }

                    svg {
                        background-color: $color-bg--transparent;
                        border-radius: 0;
                        margin: 0;
                    }
                }
            }
        }

        &__list {
            display: flex;
            column-gap: 40px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            width: 200px;

            &__image {
                background-color: $color-light;
                box-shadow: 0px 0px 15px rgba(8, 18, 33, 0.1);
                width: 100%;
                height: 280px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                svg {
                    width: 70px;
                    height: auto;
                }
            }

            &__title {
                font-size: $font-size--5;
                line-height: toRem(25);
                font-weight: $font-weight-bold;
                margin: 40px 0 0;
                text-align: center;

                &:before {
                    display: none;
                }
            }

            &__buttons {
                width: 150px;
                height: 40px;
                background-color: $color-white;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 15px;
                margin: -39px auto 0;

                .link-document {
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    background-color: $color-bg--transparent;
                    border-radius: 0;

                    &:hover, &:focus {
                        svg {
                            fill: $color-third;
                        }
                    }

                    svg {
                        margin: 0;
                        width: 20px;
                        height: 20px;
                        background-color: $color-bg--transparent;
                        border-radius: 0;
                        transition: $duration;
                    }
                }
            }
        }

        &__btnAll {
            border-color: $color-third;
        }
    }

    //======================================================================================================
    // En images
    //======================================================================================================
    .enImages {
        padding-top: 120px;

        &__subtitle {
            &:before {
                content: '';
                width: 27px;
                height: 15px;
                background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic_titre_images.svg) no-repeat;
                background-size: contain;
            }
        }

        &__content {
            display: flex;
            column-gap: 30px;
        }

        .bloc {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: calc(100% / 3);
            height: 320px;
            overflow: hidden;

            &:hover {
                .bloc {
                    &__content {
                        top: 0;
                    }
                }
            }

            &--link {
                .bloc {
                    &__title {
                        margin: 0 0 20px;
                    }

                    &__content {
                        svg {
                            display: block;
                            fill: $color-fifth;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }

            &__image {
                width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &__content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                background: linear-gradient(0deg, rgba(8, 18, 33, 0.7), rgba(8, 18, 33, 0.7));
                padding: 0 90px;
                top: 100%;
                transition: .45s ease-in-out;

                svg {
                    display: none;
                }
            }

            &__title {
                font-family: $font-family--heading;
                font-size: $font-size--5;
                line-height: toRem(25);
                font-weight: $font-weight-bold;
                color: $color-white;
                text-align: center;
            }
        }
    }

}



//======================================================================================================
// AdminAreas
//======================================================================================================
.admin-blocs {
    @include flex($direction: column, $wrap: wrap, $justifyContent: space-between);
    @include size(100%, 240px);
    margin: 40px 0;

    .bloc {
        display: flex;
        @include size(170px, 110px);
        margin-right: 20px;

        &:nth-child(1),
        &:nth-child(2) {
            @include size(370px, 240px);
        }

        &__wrapper {
            position: relative;
            @include flex($wrap: wrap);
            @include size(100%);

            &:hover {
                .bloc__content {
                    opacity: 1;
                }
            }

            &--video {
                cursor: pointer;

                &::before {
                    content: '\f126';
                    font: normal 34px/1 dashicons;
                    position: absolute;
                    z-index: 1;
                    @include flex($alignItems: center, $justifyContent: center);
                    @include size(100%);
                    color: $color-white;
                }
            }

        }

        &__image {
            position: absolute;
            @include size(100%);

            img {
                @include size(100%);
                object-fit: cover;
                object-position: center;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            @include size(100%);
            opacity: 0;
            padding: 0 20px;
            background: rgba($color-black, 0.5);
            color: $color-white;
            overflow: hidden;
            transition: all $duration ease-in-out;
        }

        &__title {
            margin-bottom: 10px;
            font-size: $font-size--5;
        }

        .popup-area {
            @include flex($alignItems: center, $justifyContent: center);
            @include fixed($top: 0, $left: 0);
            z-index: 20;
            @include size(100%);
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                @include flex($alignItems: center, $justifyContent: center);
                cursor: pointer;
                position: absolute;
                @include absolute($top: 40px);
                @extend %SpaceFull-to-Container--right;
                @include size(40px);
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        &__nav {
            width: 60px;
            height: 60px;
        }

        .alu {
            .slideshow {
                @include size(100%, 440px);
    
                &__content {
                    @include absolute($left: 110px, $bottom: 0);
                    @include size(340px, auto);
                    padding: 20px 20px 0;
                }
    
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
    
                &__description {
                    font-size: $font-size;
                }
    
                // Navigation (arrow)
                &__nav {
                    @include absolute($bottom: 0, $left: 10px);
                }
            
            }
        }

        .access {
            width: 540px;

            &__list {
                column-gap: 60px;
                row-gap: 30px;
            }
        }

        .posts {   
            &__list {
                padding-left: 60px;
            }
    
            &__nav {
                left: 60px;
            }
    
            .swiper-slide {
                width: 420px;
                height: 265px;
                margin-right: 20px;
            }
    
            &__item {
                &__content {
                    padding: 20px 20px 0;
                    right: 40px;
                    width: 300px;
                    height: 100px;
                }
    
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
    
                &__taxo {
                    font-size: $font-size--text-small;
                }
            }
        }

        .events {        
            &__list {
                padding-left: 60px;
            }
    
            &__nav {
                top: 145px;
                left: 60px;
            }
    
            .swiper-slide {
                width: 280px;
                margin-right: 20px;
            }
    
            &__item {
                &__container-img {
                    height: 205px;
                }
    
                &__date {
                    right: 20px;
                    height: 40px;
                    font-size: toRem(18);
                    line-height: toRem(20);
                    padding: 0 20px;
                }
    
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                    margin: 15px 0 0;
                }
            }
        }

        .sw {    
            .ff-stream {
                margin: 0 -20px !important;
            }
    
            .ff-item {
                height: 195px!important;
            }
    
            .ff-item .ff-content {
                padding: 30px 30px 50px;
                height: 195px!important;
            }
    
            .ff-item-cont {
                height: 195px!important;
            }
    
            .ff-item .ff-item-bar {
                height: 45px!important;
            }
    
            .ff-item .ff-img-holder{
                height: 195px!important;
            }
        }
    

        .publications {        
            &__admin {
                column-gap: 20px;
    
                &__image {
                    width: 220px;
                    height: 305px;
                }
    
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
    
                &__date {
                    margin: 0 0 35px;
                }
            }
    
            &__list {
                column-gap: 30px;
            }
    
            &__item {
                width: 160px;
    
                &__image {
                    height: 225px;
                }
    
                &__title {
                    font-size: $font-size;
                    line-height: toRem(22);
                    margin: 30px 0 0;
                }
    
                &__buttons {
                    width: 120px;
                }
            }
        }

        .enImages {
            &__content {
                column-gap: 15px;
            }
    
            .bloc {
                height: 260px;
    
                &__content {
                    padding: 0 45px;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        &__title {
            font-size: toRem(45);
        }

        .button {
            margin-top: 10px;
        }

        .alu {
            .slideshow {
                @include size(100%, 355px);
                
                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }
    
                &__content {
                    display: none;
                }
    
                // Navigation (arrow)
                &__nav {
                    @include absolute($bottom: 0, $left: 10px);
                }
            
            }
        }

        .access-demarches {
            > .container {
                column-gap: 10px;
            }
        }

        .access {
            flex-basis: 50%;
            width: auto;
            padding: 60px 0 45px;

            &__list {
                column-gap: 20px;
            }
        }

        .posts {   
            &__list {
                padding-left: 0;
            }
    
            &__nav {
                left: 0;
                top: 140px;
                bottom: auto;
            }
    
            .swiper-slide {
                width: 300px;
                height: auto;
            }
    
            &__item {
                &:hover, &:focus-within {
                    .posts__item__content {
                        &:before {
                            width: 100%;
                        }
                    }
                }
                &__content {
                    width: 100%;
                    height: auto;
                    position: relative;
                    padding: 10px 0 0;
                    right: 0;

                    &:before {
                        height: 10px;
                    }
                }

                &__container-img {
                    height: 200px;
                }
            }
        }

        .events {
            padding-top: 100px;

            &__list {
                width: 100vw;
                padding-left: 0;
            }
    
            &__nav {
                left: 0;
            }
    
            .swiper-slide {
                width: 300px;
            }
        }

        .demarches {
            flex-basis: 50%;
            padding: 80px 0 80px 40px;
        }

        .sw {
            padding-top: 100px;
    
            &__btn {
                width: 40px;
                height: 40px;
                padding: 0;
                
                span {
                    display: none;
                }
            }
    
            &__container {
                padding-left: 0;
            }

            .ff-stream {
                height: 195px!important;
                overflow: hidden;
            }
    
            .ff-item .ff-content {
                padding: 30px 40px 50px;
            }
    
            .ff-item .ff-item-bar {
                margin: 0 40px;
            }
        }

        .publications {  
            padding-top: 100px;

            &__container {
                padding-left: 0;
            }

            &__admin {
                position: relative;
                flex-direction: column;
                width: 220px;
    
                &__image {
                    width: 100%;
                    height: 230px;
                }

                &__info {
                    width: 100%;
                }

                &__category {
                    margin: 10px 0;
                }

                &__title {
                    margin: 0 0 10px;
                }
    
                &__date {
                    margin: 0;
                }
    
                &__buttons {
                    position: absolute;
                    top: 180px;
                    width: 160px;
                    height: 50px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 160px;
                    justify-content: center;
                    column-gap: 20px;
                    background-color: $color-white;
                }
            }
    
            &__list {
                column-gap: 30px;
            }
    
            &__item {
                width: 160px;
    
                &__image {
                    height: 225px;
                }
    
                &__title {
                    font-size: $font-size;
                    line-height: toRem(22);
                    margin: 30px 0 0;
                }
    
                &__buttons {
                    width: 120px;
                }
            }
        }

        .enImages {
            padding-top: 100px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .access-demarches {
            > .container {
                flex-direction: column;
            }
        }

        .alu {
            .slideshow {
                @include size(100%, 355px);  
            }
        }

        .access {
            padding: 40px 0;

            &__list {
                column-gap: initial;
            }

            &__item {
                width: calc(100% / 3);
            }

            &__label {
                font-size: toRem(14);
            }
        }

        .posts {
            position: relative;
            padding: 50px 0 80px;

            .swiper-slide {
                width: calc(90% - 20px);
            }

            &__btnAll {
                bottom: 0;
                position: absolute;
            }
        }

        .events {
            position: relative;
            padding: 60px 0 80px;

            .swiper-slide {
                width: calc(90% - 20px);
            }

            &__btnAll {
                bottom: 0;
                position: absolute;
            }
        }

        .demarches {
            padding: 40px 0;

            &:after {
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .sw {
            padding: 60px 0 70px;

            &__buttons {
                bottom: 0;
                position: absolute;
            }

            .ff-stream {
                height: 410px!important;
                margin: 0!important;
            }

            .ff-item {
                width: 100%!important;

                .ff-item-bar {
                    width: 220px!important;
                    margin: 0 auto;
                }
            }

        }

        .publications {
            position: relative;
            padding: 60px 0 80px;

            &__container {
                flex-direction: column;
                row-gap: 40px;
            }

            &__admin {
                width: 100%;
                align-items: flex-start;

                &__image {
                    width: 300px;
                    height: 230px;
                }

                &__buttons {
                    top: 190px;
                    left: 80px;
                    width: 140px;
                    height: 40px;
                    column-gap: 15px;
                    transform: none;
                }
            }

            &__list {
                column-gap: 20px;
            }

            &__item {
                width: 140px;

                &__image {
                    height: 200px;
                }

                &__title {
                    margin: 10px 0 0;
                }

                &__buttons {
                    margin: -41px auto 0;
                }
            }

            &__btnAll {
                bottom: 0;
                position: absolute;
            }
        }

        .enImages {
            position: relative;
            padding: 60px 0 80px;

            &__content {
                flex-direction: column;
            }

            .bloc {
                width: 100%;
                height: 240px;
            }

            &__btnAll {
                position: absolute;
                bottom: 0;
            }
        }
    }
}
