.footer {
    &__top {
        position: relative;

        &:after {
            content: "";
            display: block;
            height: 10px;
            width: 100%;
            background: linear-gradient(90deg,$color-second 25%,$color-third 25% 50%,$color-fourth 50% 75%,$color-fifth 75%);
        }
    }

    &__bottom {
        background-color: $color-main;
    }

    &__infos {
        display: flex;
        justify-content: space-between;
        padding: 100px 0;
    }

    &__logo {
        width: 205px;
        height: 100px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__address {
        width: 270px;
    }

    &__hours {
        width: 310px;
    }

    &__title {
        @extend h4;
        margin: 0 0 15px;
        text-transform: uppercase;

        br {
            display: none;
        }
    }

    &__text {
        line-height: toRem(20);
        font-weight: $font-weight-light;
        font-family: $font-family--heading;
    }

    &__phone {
        margin: 10px 0 0;
        display: inline-flex;
        font-weight: $font-weight-medium;
        line-height: toRem(20);
        font-family: $font-family--heading;
        position: relative;

        &:hover, &:focus-within {
            &:before {
                width: 100%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            background-color: $color-main;
            transition: $duration;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        margin-left: 20px;
    }

    &__btn {
        width: 210px;
        text-align: center;
        padding: 10px 0!important;
        margin: 0!important;
    }

    &__menu {
        height: 60px;
        display: flex;
        align-items: center;
        column-gap: 60px;

        &__item {
            flex: none;
        }

        &__link {
            font-family: $font-family--heading;
            color: $color-white;
            letter-spacing: .05rem;
            font-size: $font-size--text-small;

            &:hover, &:focus-within {
                color: $color-fifth;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            padding: 100px 0 80px;
        }

        &__logo {
            width: 165px;
            height: 80px;
        }

        &__address {
            width: 185px;
        }

        &__hours {
            width: 280px;
        }

        &__title {
            font-size: toRem(20);

            br {
                display: block;
            }
        }

        &__text {
            font-size: $font-size--text-small;
        }

        &__phone {
            font-size: $font-size--text-small;
        }

        &__buttons {
            margin-left: 0;
            row-gap: 20px;
        }

        &__btn {
            width: 200px;
        }

        &__menu {
            column-gap: 60px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            padding: 100px 0 90px;
            flex-wrap: wrap;
            row-gap: 30px;
            column-gap: 30px;
            justify-content: flex-start;
        }

        &__logo {
            width: 100%;

            img {
                width: 165px;
            }
        }

        &__buttons {
            flex-direction: row;
            column-gap: 20px;
        }

        &__menu {
            height: auto;
            justify-content: center;
            flex-wrap: wrap;

            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__infos {
            padding: 80px 0 60px;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &__address, &__hours, &__buttons {
            margin: auto;
        }

        &__buttons {
            flex-direction: column;
        }

        &__menu {
            max-width: 320px;
            margin: auto;
            column-gap: 40px;
        }
    }
}