.nav-main {
    position: relative;
    padding: 0 145px 0 470px;

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // Main menu
    .menu {
        display: flex;
        flex-wrap: wrap;
        height: 140px;
        width: 100%;
        justify-content: space-between;
        transition: .35s ease-in-out;

        &__item {
            &:hover, &:focus-within {

                .menu__link {
                    &:after {
                        width: 50px;
                    }
                }
            }

            &.menu-item-has-children {
                &:hover, &:focus-within {
                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }

                .nav-main .menu__link {
                    &:after {
                        width: 50px;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0;
            font-family: $font-family--heading;
            color: $color-text;
            text-transform: uppercase;
            letter-spacing: .05rem;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
            line-height: toRem(20);
    
            &:after {
                content: '';
                position: absolute;
                bottom: 45px;
                left: 0;
                width: 0;
                height: 5px;
                background: $color-fifth;
                transition: .25s ease-in-out;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        z-index: 1;
        width: 100%;
        padding: 40px 0;
        background: rgba($color-main, .95);
        width: 100vw;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
        right: auto;

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            padding: 2px 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 50px;
            padding: 10px 50px 10px 0;
            color: $color-white;
            font-weight: $font-weight-bold;
            border: 2px solid $color-bg--transparent;

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-white;
                opacity: .5;
                transition: all $duration;
            }

            &:hover, &:focus-within {
                border-color: $color-fifth;
                padding: 10px 35px 10px 15px;

                &::after {
                    opacity: 1;
                    right: 10px;
                }
            }

            &--grandchild {
                display: flex;
                padding: 10px 20px 10px 0;
                font-weight: $font-weight;

                &::after {
                    display: none;
                }

                &:hover, &:focus-within {
                    padding: 10px 5px 10px 15px;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}

.header--fixed {
    .nav-main {
        .menu {
            height: 90px;

            &__link {
                &:after {
                    bottom: 25px;
                }
            }
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        padding: 0 115px 0 315px;

        .menu {
            height: 120px;

            &__link {
                font-size: $font-size;

                &:after {
                    bottom: 40px;
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-main;
            padding: 40px 0;
            transition: $duration;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row-reverse;
            column-gap: 10px;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-family: $font-family--heading;
            font-size: $font-size;
            font-weight: $font-weight-bold; 
            color: $color-text;
            text-transform: uppercase;
            letter-spacing: .05rem;
            padding: 0;
            margin: 0 0 0 auto;
            height: 120px;
            transition: $duration;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-text;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background: $color-text;
            transition: $duration;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-text;
                transition: $duration;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: 2px solid $color-white;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            height: auto;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                border-bottom: 1px solid rgb($color-white, .3);
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: all $duration;
                    }
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            right: 0;
                        }
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 40px 0 60px 0;
            background-color: $color-main;
            transform: none;
            transition: $duration;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;
                border: none;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        padding: 0;

        &__button {
            height: 40px;
            margin: 0;
            color: $color-white;

            &:hover, &:focus {
                color: $color-white;
                background-color: $color-bg--transparent;
            }
        }

        &__icon {
            background-color: $color-white;

            &::before, &::after {
                background-color: $color-white;
            }
        }
    }
}
