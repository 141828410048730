.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 40px 0;
    background: $color-second;

    .return {
        a {
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: $font-size;
            font-weight: $font-weight-bold;
            color: $color-white;
            letter-spacing: .05rem;
            transition: all $duration;

            &:after {
                content: '';
                position: absolute;
                height: 3px;
                width: 0;
                bottom: 0;
                left: 30px;
                background-color: $color-white;
                transition: $duration;
            }

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-white;
                transition: all $duration;
            }

            &:hover {
                color: $color-white;

                &:after {
                    width: calc(100% - 30px);
                }

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 112px;

        .navigation_link {
            color: $color-white;

            // Recup style ~ ligne 9
            a {
                position: relative;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: $font-size;
                font-weight: $font-weight-bold;
                color: $color-white;
                letter-spacing: .05rem;
                transition: all $duration;
    
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-white;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: 0;
                    bottom: 0;
                    left: 30px;
                    background-color: $color-white;
                    transition: $duration;
                }

                &:hover, &:focus, &:focus-within {
    
                    &:after {
                        width: calc(100% - 30px);
                    }
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

            a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-white;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: 0;
                    bottom: 0;
                    left: 0;
                    background-color: $color-white;
                    transition: $duration;
                }

                &:hover, &:focus, &:focus-within {
    
                    &:before {
                        width: calc(100% - 30px);
                    }
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                a {
                    color: $color-white;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}