.breadcrumb {
    ul  {
        display: flex;
        flex-wrap: wrap;
        background: $color-white;
        margin-top: -40px;
        min-height: 40px;
        align-items: center;
        position: relative;
        width: fit-content;
        padding: 7px 20px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;

        &:after {
            content: '';
            display: inline-block;
            margin: 0 5px;
            background: url(/wp-content/themes/cranves-sales/assets/src/images/sprite-images/pictos/ic-nav-chevron-left.svg) no-repeat;
            width: 20px;
            height: 20px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }


        a {
            color: $color-text;
            line-height: toRem(25);
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 0;
                background-color: $color-dark;
                transition: $duration;
            }

            &:hover {
                color: $color-text;
                
                &:after {
                    width: 100%;
                }
            }
        }

    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
            box-shadow: 0px -1px 0 rgb(32 32 34 / 10%);
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        }
    }

}
